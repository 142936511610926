<template>
    <div v-if="visible" class="leader-board" >
        <div class="backdrop"></div>
        <div class="panel">
            <div class="panel-left">
                <div class="caption">Leaderboard:</div>
                <div class="panel-groups">
                    <!-- <div v-for="(group,index) in this.testleaderboard"  -->
                    <div v-for="(group,index) in this.$store.getters['groups/leaderboard']" 
                    class="group"
                    :class="(group.online)?'online':'offline'"
                    :key="index">
                        {{index + 1}}. 
                        <div class="groupnr">{{group.index}}</div> 
                        <div class="groupname">{{ group.groupname }}</div>
                        <div class="score-status">
                            <div class="score">{{ group.score }}</div>
                            <div class="status" :class="group.status">{{getGroupStatus(group)}}</div> 
                        </div>
                    </div>                
                </div>
            </div>
            <div class="panel-right">
                <!-- <app-button class="button"  color="purple" bevel="true" caption="Vernieuw"  @click="reload" /> -->
                <app-button class="button"  color="purple" bevel="true" :caption="$t('button-close')"  @click="hide" />
            </div>
        </div>
    </div>  
</template>

<script>
    //import {mapState} from 'vuex';
    import AppButton from  "@/components/ui/AppButton.vue";

    export default {
        name: 'leader-board',
        components: {
            AppButton
        },
        data() {
            return {
                visible: false,
                testleaderboard: [
                    {
                        index:1,
                        online: true, 
                        score: 100,
                        groupname: 'jksdg jaajsdh ja jsadghjhsagd jahgdhagjdgajs',
                        status: 'round',
                        round:0
                    },
                    {
                        index:2,
                        online: true, 
                        score: 130,
                        groupname: 'asssd jksdg jaajsdh ja jsadghjhsagd jahgdhagjdgajs',
                        status: 'started',
                        round: 0
                    },
                    {
                        index:3,
                        online: true, 
                        score: 130,
                        groupname: 'asssd jksdg jaajsdh ja jsadghjhsagd jahgdhagjdgajs',
                        status: 'round',
                        round:1
                    },
                    {
                        index:4,
                        online: false, 
                        score: 130,
                        groupname: 'asssd jksdg jaajsdh ja jsadghjhsagd jahgdhagjdgajs',
                        status: 'finished'
                    },
                    {
                        index:5,
                        online: true, 
                        score: 130,
                        groupname: 'asssd jksdg jaajsdh ja jsadghjhsagd jahgdhagjdgajs',
                        status: 'finished'
                    },
                    {
                        index:6,
                        online: true, 
                        score: 130,
                        groupname: 'asssd jksdg jaajsdh ja jsadghjhsagd jahgdhagjdgajs',
                        status: 'finished'
                    },
                    {
                        index:7,
                        online: true, 
                        score: 130,
                        groupname: 'asssd jksdg jaajsdh ja jsadghjhsagd jahgdhagjdgajs',
                        status: 'finished'
                    },
                    {
                        index:8,
                        online: true, 
                        score: 130,
                        groupname: 'asssd jksdg jaajsdh ja jsadghjhsagd jahgdhagjdgajs',
                        status: 'finished'
                    },
                    {
                        index:9,
                        online: true, 
                        score: 130,
                        groupname: 'asssd jksdg jaajsdh ja jsadghjhsagd jahgdhagjdgajs',
                        status: 'finished'
                    }
                ]
            }
        },
        computed: {
            // ...mapState(['groups']),
            // ...mapState('socket', {
            //     online_groups: state => state.online_groups
            // }),   
            // groupstatus() {
            //     let status = [];
            //     // status = [
            //     //       {'name':'akjhjdjsahdaskd'},
            //     //       {'name':'jaGJagsjhgjhasgjh'},
            //     //       {'name':'akjhjdjsahdaskd'},
            //     //       {'name':'jaGJagsjhgjhasgjh'},
            //     //       {'name':'akjhjdjsahdaskd'},
            //     //       {'name':'jaGJagsjhgjhasgjh'},
            //     //       {'name':'akjhjdjsahdaskd'},
            //     //       {'name':'jaGJagsjhgjhasgjh'},
            //     //       {'name':'akjhjdjsahdaskd'},
            //     //       {'name':'jaGJagsjhgjhasgjh'},
            //     //       {'name':'akjhjdjsahdaskd'},
            //     //       {'name':'jaGJagsjhgjhasgjh'},
            //     //     ]                
            //     // these groups are currently present in the room
            //     this.online_groups.forEach((groupname, index) => {
            //         let g = {
            //             name:groupname,
            //             status:'online'
            //         };
            //         status.push( g );
            //     });
            //     // also check the groups from the database, lists might differ
            //     this.groups.forEach((group, index) => {
            //         if (!this.online_groups.includes( group.group )){
            //             let g = {
            //                 name:group.group,
            //                 status:'offline'
            //             };
            //             status.push( g );
            //         }
            //     });
            //     return status;
            // }         
        },
        methods: {   
            show() {
                //console.log("show")
                this.visible = true;
                //this.reload();
            },
            reload() {
                // if (this.groups.length == 0) {
                //     this.$store.dispatch("getAllGroups");
                // }
                // this.$store.dispatch("socket/pingAllGroups");
            },
            hide() {
                this.visible = false;
            },
            getGroupStatus(group) {
                //console.log(group)

                let status = "wacht";
                switch(group.status) {
                    // case "online": {
                    //     break;
                    // }
                    case "started": {
                        status = "ronde " + (Number(group.round) + 1);
                        break;
                    }
                    case "round": {
                        status = "ronde " + (Number(group.round) + 1);
                        break;
                    }
                    case "finished": {
                        status = "klaar";
                        break;
                    }
                }
                return status;
            }
        }
    };
</script>


<style scoped>

.leader-board {
    position: absolute;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    z-index: 4;
}

.backdrop {
  position: absolute;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  background-color: white;
  opacity: 0.4;
}

.panel {
  position: absolute;
  left:0;
  top:160px;  
  background-color: var(--green-dark-color);
  display:flex;
  flex-direction: row;

  width:100vw;
  padding-bottom: 15px;
  /* height:  calc(292px / var(--scale));  */
}

.panel-left {
    width:auto;
    position: relative;
    /* display: flex; */
    width: 75vw;
    /* background-color: red; */
}

.panel-right {
  width:27vw;
  display: flex;
  flex-direction: column;
  /* justify-content: cent */
  align-items:flex-end;
  margin-top: 100px;
  margin-right: 60px;
}   

.caption {
    font-family:'Fira Sans SemiBold';
    /* font-size: calc(18px / var(--scale)); */
    font-size: 3vh;
    color:var(--light-text-color);
    margin: 20px 57px 8px;
    /* margin-top:10px;
    margin-left:57px; */
    /* background-color: green; */
}
.panel-groups {
    height: 40vh;
    /* margin-top: 40px; */
    margin-left:50px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 20px;
    /* row-gap: .1vh; */
    /* justify-content:safe; */

    /* background-color: blue; */
}

.groupnr {
    display: inline-block;
    background-color: black;
    color:white;
    width: 3vh;
    height:3vh;
    /* margin: 5px 3px 0 3px ; */
    text-align: center;
    line-height: 3vh
}


/* .groupname {
    width: calc(363px / var(--scale));
    height: calc(41px / var(--scale));
    line-height: calc(41px / var(--scale));
    background-color: white;
    font-family:'Fira Sans SemiBold';
    font-size: calc(16px / var(--scale));
    padding: 0 30px 0 6px;
    margin: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.groupname.online {
    opacity: 1;
}

.groupname.offline {
    opacity: .5;
} */

.button {
    margin-bottom: 20px;
}

.group {
    /* width: calc(700px / var(--scale));
    height: calc(41px / var(--scale));
    line-height: calc(41px / var(--scale));
    font-family:'Fira Sans SemiBold';
    font-size: calc(30px / var(--scale));
    margin: 4px; */

    /* width: calc(450px / var(--scale)); */
    width: 48%;
    /* height: calc(41px / var(--scale)); */
    height: 5vh;
    /* line-height: calc(41px / var(--scale)); */
    line-height: 5vh;
    background-color: white;
    font-family:'Fira Sans SemiBold';
    /* font-size: calc(16px / var(--scale)); */
    font-size: 1.8vh;
    padding-left: 6px;
    /* padding: 0 30px 0 6px; */
    margin: 4px;


    display:flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;
}

group.online {
    opacity: 1;
}

group.offline {
    opacity: .5;
}


/* .score {
    width: calc(100px / var(--scale));
    text-align: right;

} */

.groupname {
    width: 50%;
    /* width: calc(220px / var(--scale)); */
    /* background-color: red; */

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
    /* border-bottom: 1px solid black; */
}

.score-status {
    display: flex;
    width: 27%;
}

.score {
    /* width: calc(70px / var(--scale)); */
    /* width: 12%; */
    width: 45%;
    text-align: center;
    background-color: black;
    color:white;
    /* margin-left: 5px; */
    font-family: 'Score Board';
    letter-spacing: 1px;
}





.status {
    /* font-family:'Fira Sans Regular'; */
    /* background-color: black;
    color:white; */
    /* font-size: calc(20px / var(--scale)); */
    /* width: calc(70px / var(--scale)); */
    /* width: 15%; */
    width: 55%;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space:nowrap;
    /* font-size: calc(14px / var(--scale)); */
    background-color: var(--purple-light-color);
}

.status.round {
    background-color: var(--purple-middle-color);
    color:white;
}

.status.started {
    background-color: var(--purple-middle-color);
    color:white;
}

.status.finished {
    background-color: var(--purple-dark-color);
    color:white;
}




</style>
