<template>
    <div class="page">    
        <!-- <div class="selfstart-code">
            <span class="title">LEERLING CODE: VOGELS </span>
        </div> -->
        <div v-if="this.$route.params.option==='instructions'" class="content" >
            <div class="panel-left" >
                    
                <div class="panel-instructions">
                    <div class="instructions-top">
                        <span class="title">{{$t('begeleider-endgame-caption')}}</span>
                        <span class="title">{{$t('begeleider-endgame-caption-code')}}</span>
                    </div>
                    <div class="instruction" v-html="$t('begeleider-endgame-instructions')"></div>
                </div>

            </div>
            <div class="panel-right">
                <app-button class="button" ref="nextbutton" color="purple" bevel="true" :caption="$t('button-next')"  @click="$router.push('/begeleider/endgame/leaderboard')" />
            </div>
        </div>
        <div  v-if="this.$route.params.option==='leaderboard'" class="content">
            <div class="leader-board" >
                
                <span class="title selfstart-code">{{$t('begeleider-endgame-caption-code')}}</span>

                <img v-if="!this.$store.getters['groups/allgroupsFinishedEndgame']" class="button-leaderboard game-button" src="~@/assets/bt-leaderboard.png" @click="toggleLeaderBoard"  />

                <div v-if="!showLeaderBoard" class="panel">
                    <div class="caption">{{ caption }}</div>
                    <div class="panel-groups">
                        <!-- <div class="group">
                            <div class="groupname">groep:</div> <div class="score">score:</div>  <div class="status">status:</div> 
                        </div> -->

                        <div v-for="(group,index) in this.$store.getters['groups/groups']" 
                        class="group"
                        :class="(group.online)?'online':'offline'"
                        :key="index">                            
                            <div class="groupname">{{ group.groupname }}</div> 
                            <div class="score">{{group.score}} </div>  
                            <div class="status" :class="group.status">{{getGameStatus(group)}}</div> 
                        </div>                

                    </div>
                </div>
                <div v-else class="panel">
                    <div class="caption">{{ caption }}</div>
                    <div class="panel-groups">
                        <div v-for="(group,index) in this.$store.getters['groups/leaderboard']" 
                        class="group"
                        :class="(group.online)?'online':'offline'"
                        :key="index">
                              <div class="groupname">{{index+1}}. {{ group.groupname }}</div>  
                              <div class="scoreboard">{{group.score}}  </div>
                        </div>                
                    </div>
                </div>  
                <!-- <div class="back-to-menu" >
                </div>      -->
                <app-button v-if='this.$store.getters["user/isInVWNW"]' class="back-to-menu-button" color="purple" bevel="true" :caption="$t('button-back-to-menu')"  @click="handleBackToMenu" />

            </div>          

        </div>
        <confirm-panel ref="confirm" class="confirm-panel" @ok="handleConfirmOk" @cancel="handleConfirmCancel" />

    </div>

</template>

<script>
    //import {mapState} from 'vuex';
    import AppButton from  "@/components/ui/AppButton.vue";
    import ConfirmPanel from "@/components/ui/ConfirmPanel.vue";

    export default {
        name: 'endgame-screen-begeleider',
        components: {
            AppButton,
            ConfirmPanel            
        },
        data() {
            return {
                leaderBoardToggle: false,
                endgameplaying: true
            }
        },
        computed: {
           caption() {
               if (this.$store.getters['groups/allgroupsFinishedEndgame']) {
                   return this.$i18n.t('begeleider-endgame-finished-caption');
               }
               else {
                   return this.$i18n.t('begeleider-endgame-playing-caption');
               }
           },
           showLeaderBoard() {
               if (this.$store.getters['groups/allgroupsFinishedEndgame']) {
                   return true;
               }
               else {
                   return this.leaderBoardToggle;
               }

           }
        },
        methods: {   
            handleConfirmOk(id) {
                this.$refs.confirm.hide();

                if (id === "begeleider-confirm-back-to-menu") {
                    console.log( process.env.VUE_APP_VWNW_HOST )
                    console.log("back-to-menu")
                    parent.postMessage("back-to-menu", process.env.VUE_APP_VWNW_HOST);
                }
            },
            handleConfirmCancel() {
                this.$refs.confirm.hide();
            },            
            handleBackToMenu(){
                // 
                this.$refs.confirm.show("begeleider-confirm-back-to-menu");
            },
            toggleLeaderBoard() {
                this.leaderBoardToggle = !this.leaderBoardToggle;
            },
            getGameStatus(group) {
                //console.log(group)

                let status = "nog niet begonnen";

                if (!group.online) {
                    status = "offline";
                }
                else {
                    switch(group.status) {
                        // case "online": {
                        //     break;
                        // }
                        case "started": {
                            status = "speler " + (group.player + 1) + " speelt"
                            break;
                        }
                        case "resumed": {
                            status = "speler " + (group.player + 1) + " speelt"
                            break;
                        }
                        case "finished": {
                            status = "klaar";
                            break;
                        }
                    }
                }

                return status;
            }
        },
        mounted() {
            if (this.$store.getters['user/roomname'] === '' ) {
                this.$router.push("/");
            }

            this.$store.dispatch("setHeaderContent", {caption: "Eindspel "  } );

        
            // listen to the location updates of the students
            this.$socket.client.on("message",(data)=>{
                // console.log("receive")
                 console.log(data)
                switch(data.student_action) {
                    case "statusUpdate": {
                        this.$store.dispatch('groups/updateGroup',data.payload);                        
                    }                
                }
            });

            this.$socket.client.on("clientleft",(socketid) => {
                console.log("left: " + socketid)
                this.$store.dispatch('groups/removeGroup',socketid);
            });

           // this.$store.dispatch('groups/testSort'); 

        },
    };
</script>


<style scoped>

.page {
    background-color: var(--blue-middle-color);

}

.content {
    position: relative;
    margin-top: 60px;
    width:100vw;
    height: 90vh;
    display: flex;
    /* justify-content: center; */


    /* flex-direction: row; */
}


.panel-left {
  width: 73vw;
  height: 75vh;

  font-family:'Fira Sans Bold';
  font-size: calc(35px / var(--scale));
  color: var(--yellow-dark-color);
  display: flex;
  align-items: center;
  justify-content: center;


}

.panel-instruction {

}

.instructions-top {
    display: flex;
    justify-content:space-between;
}



.title {
  font-family: 'Fira Sans Bold';
  font-size: calc(23px / var(--scale) );
  line-height: calc(23px / var(--scale) );

  color:var(--purple-dark-color);
  border-bottom: 3px solid var(--purple-dark-color);
  text-transform: uppercase;
}

.instruction {
    font-family: 'Fira Sans Regular';
    color:var(--dark-text-color);
    font-size: calc(26px / var(--scale) );
    width: calc(729px / var(--scale) );
    margin-top: calc(60px / var(--scale) );
}

.panel-right {
    width:27vw;
    display: flex;
    align-items: center;
    justify-content: center;

    
}

.panel-right .button {
  margin-top:  calc(-130px / var(--scale) );
}

.leader-board {
    /* background-color: red; */
    display:flex;
    flex-direction: column;
    align-items: center;
}

.selfstart-code {
    position: absolute;
    top: calc(49px / var(--scale) );
    left: calc(900px / var(--scale) );
}

.back-to-menu-button {
    margin-top: auto;
    margin-bottom: 30px;
}

.caption {
    font-family:'Fira Sans SemiBold';
    font-size: calc(34px / var(--scale));
    /* color:var(--light-text-color); */
    margin: 30px 57px;
    color: black;
    /* margin-top:10px;
    margin-left:57px; */
    /* background-color: green; */
}
.panel-groups {
    /* height: 40vh; */
    /* margin-top: 40px; */
    margin-left:50px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    /* background-color: white; */
}

.group {
    width: calc(700px / var(--scale));
    height: calc(39px / var(--scale));
    line-height: calc(39px / var(--scale));
    font-family:'Fira Sans SemiBold';
    font-size: calc(28px / var(--scale));
    margin: 4px;

    display:flex;
    flex-direction: row;

    justify-content: space-between;
}

.group.online {
    opacity: 1;
}

.group.offline {
    opacity: .5;
}


.score {
    width: calc(100px / var(--scale));
    text-align: center;

    /* border-bottom: 1px solid black; */

}

.scoreboard {
    width: calc(100px / var(--scale));
    text-align: center;
    font-family: 'Score Board';
    letter-spacing: 1px;
    background-color: black;    
    color:white;
    border: 1px solid white;

    /* border-bottom: 1px solid black; */

}

.groupname {
    width: calc(400px / var(--scale));
    /* background-color: red; */

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
    /* border-bottom: 1px solid black; */
}


.status {
    font-family:'Fira Sans Regular';
    background-color: black;
    color:white;
    font-size: calc(16px / var(--scale));
    width: calc(150px / var(--scale));
    text-align: center;
}

.status.online {
    background-color: black;
    color:white;
}

.status.started {
    background-color: var(--purple-dark-color);
    color:white;
}

.status.resumed {
    background-color: var(--purple-dark-color);
    color:white;
}

.status.finished {
    background-color: var(--green-dark-color);
    color:white;
}

.button-leaderboard {
    position: absolute;
    top: calc(30px / var(--scale) ); 
    right:calc(30px / var(--scale) ); 
    width: calc(58px / var(--scale) ); 
    height: calc(58px / var(--scale) ); 
}


</style>
